import React, { useEffect } from 'react';

import $ from 'jquery'; 
function Tennis() {
   
useEffect(() => {
    $(document).ready(function(){
        $('#closebetbox').click(function(){
            $('.bettingbox').css("display", "none");
        });
    });
}, []);
  return (
    <>
      <div class="card-header tab_first d-flex">
    <div>
        <ul class="nav nav-tabs card-header-tabs" id="outerTab" role="tablist">
            <li class="nav-item activetab">
                <a class="nav-link active" data-toggle="tab" href="#tab-1" aria-controls="tab-1" role="tab"
                    aria-expanded="true"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tab-2" aria-controls="tab-2" role="tab"></a>
            </li>
        </ul>

    </div>

    </div>

    <section>
    <main id="main" class="main">
        <div class="container">

            <div class="row">
                <div class="col-md-8  padding_custom">
                    <div class="tennis_section">
                        <div class="gamedashboard">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-10 mx-auto">
                                        <div class="footerball">
                                            <div class="d-flex justify-content-between">
                                                <div class="teamvsteam">
                                                    <h3>Not started</h3>
                                                    <div class="team1">
                                                        <img src="assets/img/soccer-shadow-overlay.png" width="20"/>
                                                        <span>Team1</span>
                                                    </div>
                                                    <div class="team2">
                                                        <img src="assets/img/soccer-shadow-overlay.png" width="20"/>
                                                        <span>Team2 </span>
                                                    </div>
                                                </div>
                                                <div class="countdown">

                                                    <div class="text-center">
                                                        <div id="countDiv" class="counter-div">
                                                            <div class="counter-element">
                                                                <div class="number-div">
                                                                    <div class="days"></div>
                                                                </div>
                                                                <p class="element-text">Days</p>
                                                            </div>
                                                            <div class="counter-element">
                                                                <div class="number-div">
                                                                    <div class="hours"></div>
                                                                </div>
                                                                <p class="element-text">Hours</p>
                                                            </div>
                                                            <div class="counter-element">
                                                                <div class="number-div">
                                                                    <div class="minutes"></div>
                                                                </div>
                                                                <p class="element-text et-mobile">Min.</p>
                                                                <p class="element-text et-desktop">Minutes</p>
                                                            </div>
                                                            <div class="counter-element">
                                                                <div class="number-div">
                                                                    <div class="seconds"></div>
                                                                </div>
                                                                <p class="element-text et-mobile">Sec.</p>
                                                                <p class="element-text et-desktop">Seconds</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="matchicon">
                                                <div class="goalmatch">
                                                    <span>-</span>
                                                    <img src="assets/img/13.svg" width="20"/>
                                                    <span>-</span>
                                                </div>
                                                <div class="goalmatch">
                                                    <span>-</span>
                                                    <img src="assets/img/8.svg" width="20"/>
                                                    <span>-</span>
                                                </div>
                                                <div class="goalmatch">
                                                    <span>-</span>
                                                    <img src="assets/img/9.svg" width="20"/>
                                                    <span>-</span>
                                                </div>
                                                <div class="goalmatch">
                                                    <span>-</span>
                                                    <img src="assets/img/24.svg" width="20"/>
                                                    <span>-</span>
                                                </div>
                                                <div class="goalmatch">
                                                    <span>-</span>
                                                    <img src="assets/img/25.svg" width="20"/>
                                                    <span>-</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bg-color-section">
                                            <ul class="nav nav-tabs" id="interest_tabs">
                                              
                                                <li><a href="#" data-toggle="tab">Live</a></li>
                                                <li><a href="#" data-toggle="tab">HeadMap</a></li>
                                                <li><a href="#" data-toggle="tab">Lineups</a></li>
                                                <li><a href="#h2h" data-toggle="tab" class="tabfootball">H2H</a></li>
                                                <li><a href="#" data-toggle="tab">INCIDENTS</a></li>
                                                <li><a href="#" data-toggle="tab">STATS</a></li>
                                                <li><a href="#STanding" data-toggle="tab"
                                                        class="tabfootball">STanding</a></li>

                                            </ul>

                                           
                                            <div class="tab-content">
                                               
                                                <div id="h2h" class="tab-pane">
                                                    <ul class="nav nav-tabs" id="experience_tabs">
                                                        <li><a href="#experience_director" data-toggle="tab">League</a>
                                                        </li>
                                                        <li><a href="#experience_actor" data-toggle="tab">Home</a>
                                                        </li>
                                                        <li><a href="#experience_producer" data-toggle="tab">Away</a>
                                                        </li>
                                                        <li><a href="#experience_scene_design" data-toggle="tab">Form
                                                            </a></li>

                                                    </ul>
                                                </div>

                                               
                                                <div id="STanding" class="tab-pane">
                                                    <ul class="nav nav-tabs" id="STanding">
                                                        <li><a href="#photos_popular" data-toggle="tab">Popular</a>
                                                        </li>
                                                        <li><a href="#photos_unique" data-toggle="tab">Unique</a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                
                                                <div id="video" class="tab-pane">
                                                    <ul class="nav nav-tabs" id="video_tabs">
                                                        <li><a href="#video_popular" data-toggle="tab">Popular</a>
                                                        </li>
                                                        <li><a href="#video_unique" data-toggle="tab">Unique</a>
                                                        </li>
                                                    </ul>
                                                </div>

                                              


                                            </div>

                                         
                                            <div class="tab-content">
                                                <div id="experience_director" class="tab-pane">
                                                    <i>experience_director interests go here</i>
                                                </div>
                                                <div id="experience_actor" class="tab-pane">
                                                    <i>experience_actor interests go here</i>
                                                </div>
                                                <div id="experience_producer" class="tab-pane">
                                                    <i>experience_producer interests go here</i>
                                                </div>
                                                <div id="experience_scene_design" class="tab-pane">
                                                    <i>experience_scene design interests go here</i>
                                                </div>
                                                <div id="experience_recognition" class="tab-pane">
                                                    <i>experience_recognition interests go here</i>
                                                </div>
                                                <div id="experience_education" class="tab-pane">
                                                    <i>experience_education interests go here</i>
                                                </div>
                                            </div>

                                           
                                            <div class="tab-content">
                                                <div id="brands_popular" class="tab-pane">
                                                    <i>brands_popular interests go here</i>
                                                </div>
                                                <div id="brands_unique" class="tab-pane">
                                                    <i>brands_unique interests go here</i>
                                                </div>
                                            </div>

                                          
                                            <div class="tab-content">
                                                <div id="media_popular" class="tab-pane">
                                                    <i>media_popular interests go here</i>
                                                </div>
                                                <div id="media_unique" class="tab-pane">
                                                    <i>media_unique interests go here</i>
                                                </div>
                                            </div>

                                         
                                            <div class="tab-content">
                                                <div id="music_popular" class="tab-pane">
                                                    <i>music_popular interests go here</i>
                                                </div>
                                                <div id="music_unique" class="tab-pane">
                                                    <i>music_unique interests go here</i>
                                                </div>
                                            </div>

                                          
                                            <div class="tab-content">
                                                <div id="public_figures_popular" class="tab-pane">
                                                    <i>public_figures_popular interests go here</i>
                                                </div>
                                                <div id="public_figures_unique" class="tab-pane">
                                                    <i>public_figures_unique interests go here</i>
                                                </div>
                                            </div>

                                          
                                            <div class="tab-content">
                                                <div id="sports_popular" class="tab-pane">
                                                    <i>sports_popular interests go here</i>
                                                </div>
                                                <div id="sports_unique" class="tab-pane">
                                                    <i>sports_unique interests go here</i>
                                                </div>
                                            </div>

                                          
                                            <div class="tab-content">
                                                <div id="tv_movies_popular" class="tab-pane">
                                                    <i>tv_movies_popular interests go here</i>
                                                </div>
                                                <div id="tv_movies_unique" class="tab-pane">
                                                    <i>tv_movies_unique interests go here</i>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-success text-white p-2 d-flex justify-content-between vsmatch d-flex">
                        <p>PORTUGAL Liga Portugal 2  Leixoes v Santa Clara</p>
                        <p>21/05/2021</p>
                    </div>
                    <div class="mt-2">

                    <div class="accordion d-flex justify-content-between align-items-center">Over Under 05 <button class="btn btn-success btn-sm">Cashout</button></div>
                        <div class="panel">

                            <div class="matchodds d-flex">
                                <div class="top_header"></div>
                                <div class="heading_box d-flex">
                                    <div class="back">Back</div>
                                    <div class="lay">Lay</div>
                                </div>
                            </div>

                            <div class="match_details">
                                <div class="matchodds_team">Leixoes</div>
                                <div class="boxclass back1 text-white matchoddsamount">
                                    <span>34.49</span>
                                    <small>10.5k</small>
                                </div>
                                <div class="boxclass back2 text-white matchoddsamount">
                                    <span>34.49</span>
                                    <small>10.5k</small>
                                </div>
                                <div class="boxclass back3 text-white matchoddsamount">
                                    <span>34.49</span>
                                    <small>10.5k</small>
                                </div>
                                <div class="boxclass back4 text-white matchoddsamount">
                                    <span>34.49</span>
                                    <small>10.5k</small>
                                </div>
                                <div class="boxclass back5 text-white matchoddsamount">
                                    <span>34.49</span>
                                    <small>10.5k</small>
                                </div>
                                <div class="boxclass back6 text-white matchoddsamount">
                                    <span>34.49</span>
                                    <small>10.5k</small>
                                </div>
                            </div>
                            <div class="match_details">
                                <div class="matchodds_team">Leixoes</div>
                                <div class="boxclass back1 text-white matchoddsamount">
                                    <span>34.49</span>
                                    <small>10.5k</small>
                                </div>
                                <div class="boxclass back2 text-white matchoddsamount">
                                    <span>34.49</span>
                                    <small>10.5k</small>
                                </div>
                                <div class="boxclass back3 text-white matchoddsamount">
                                    <span>34.49</span>
                                    <small>10.5k</small>
                                </div>
                                <div class="boxclass back4 text-white matchoddsamount">
                                    <span>34.49</span>
                                    <small>10.5k</small>
                                </div>
                                <div class="boxclass back5 text-white matchoddsamount">
                                    <span>34.49</span>
                                    <small>10.5k</small>
                                </div>
                                <div class="boxclass back6 text-white matchoddsamount">
                                    <span>34.49</span>
                                    <small>10.5k</small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-12 col-sm-12 pr_1">
                                <div class="mt-2">

                                <div class="accordion d-flex justify-content-between align-items-center">Over Under 05 <button class="btn btn-success btn-sm">Cashout</button></div>
                                    <div class="panel">

                                        <div class="boomarkar d-none-mobile">
                                            <div class="Name_nation"><span class="max-bet">
                                                    Min:<span>100</span>
                                                    Max:<span>1L</span></span></div>
                                            <div class="button_backlay back-title">Back</div>
                                            <div class="lay button_backlay lay-title">Lay</div>
                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>

                                    </div>




                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-12 col-sm-12 pl_1">
                                <div class="mt-2">

                                    <div class="accordion d-flex justify-content-between align-items-center">Over Under 05 <button class="btn btn-success btn-sm">Cashout</button></div>
                                    <div class="panel">

                                        <div class="boomarkar d-none-mobile">
                                            <div class="Name_nation"><span class="max-bet">
                                                    Min:<span>100</span>
                                                    Max:<span>1L</span></span></div>
                                            <div class="button_backlay back-title">Back</div>
                                            <div class="lay button_backlay lay-title">Lay</div>
                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>

                                    </div>




                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-12 col-sm-12 pr_1">
                                <div class="mt-2">

                                <div class="accordion d-flex justify-content-between align-items-center">Over Under 05 <button class="btn btn-success btn-sm">Cashout</button></div>
                                    <div class="panel">

                                        <div class="boomarkar d-none-mobile">
                                            <div class="Name_nation"><span class="max-bet">
                                                    Min:<span>100</span>
                                                    Max:<span>1L</span></span></div>
                                            <div class="button_backlay back-title">Back</div>
                                            <div class="lay button_backlay lay-title">Lay</div>
                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>

                                    </div>




                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-12 col-sm-12 pl_1">
                                <div class="mt-2">

                                    <div class="accordion d-flex justify-content-between align-items-center">Over Under 05 <button class="btn btn-success btn-sm">Cashout</button></div>
                                    <div class="panel">

                                        <div class="boomarkar d-none-mobile">
                                            <div class="Name_nation"><span class="max-bet">
                                                    Min:<span>100</span>
                                                    Max:<span>1L</span></span></div>
                                            <div class="button_backlay back-title">Back</div>
                                            <div class="lay button_backlay lay-title">Lay</div>
                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>

                                    </div>




                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-lg-4 col-12 col-sm-12 pr_1">
                                <div class="mt-2">

                                <div class="accordion d-flex justify-content-between align-items-center">Over Under 05 <button class="btn btn-success btn-sm">Cashout</button></div>
                                    <div class="panel">

                                        <div class="boomarkar d-none-mobile">
                                            <div class="Name_nation"><span class="max-bet">
                                                    Min:<span>1</span>
                                                   </span></div>
                                            <div class="button_backlay back-title">Back</div>
                                            <div class="lay button_backlay lay-title">Lay</div>
                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass  back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>

                                    </div>




                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4 col-12 col-sm-12 pl_1">
                                <div class="mt-2">

                                    <div class="accordion d-flex justify-content-between align-items-center">Over Under 05 <button class="btn btn-success btn-sm">Cashout</button></div>
                                    <div class="panel">

                                        <div class="boomarkar d-none-mobile">
                                            <div class="Name_nation"><span class="max-bet">
                                                    Min:<span>1</span>
                                                   </span></div>
                                            <div class="button_backlay back-title">Back</div>
                                            <div class="lay button_backlay lay-title">Lay</div>
                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>

                                    </div>




                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4 col-12 col-sm-12 pl_1">
                                <div class="mt-2">

                                    <div class="accordion d-flex justify-content-between align-items-center">Over Under 05 <button class="btn btn-success btn-sm">Cashout</button></div>
                                    <div class="panel">

                                        <div class="boomarkar d-none-mobile">
                                            <div class="Name_nation"><span class="max-bet">
                                                    Min:<span>1</span>
                                                   </span></div>
                                            <div class="button_backlay back-title">Back</div>
                                            <div class="lay button_backlay lay-title">Lay</div>
                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>

                                    </div>




                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-12 col-sm-12 pr_1">
                                <div class="mt-2">

                                <div class="accordion d-flex justify-content-between align-items-center">Over Under 05 <button class="btn btn-success btn-sm">Cashout</button></div>
                                    <div class="panel">

                                        <div class="boomarkar d-none-mobile">
                                            <div class="Name_nation"><span class="max-bet">
                                                    Min:<span>1</span>
                                                   </span></div>
                                            <div class="button_backlay back-title">Back</div>
                                            <div class="lay button_backlay lay-title">Lay</div>
                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass  back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>
                                        <div class="bookmarker_details">
                                            <div class="bookmarker_points">Under 0.5 goals</div>

                                            <div class="boxclass back2 text-dark matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>
                                            <div class="boxclass lay text-dark  matchoddsamount">
                                                <span>34.49</span>
                                                <small>10.5k</small>
                                            </div>

                                        </div>

                                    </div>




                                </div>
                            </div>
</div>
                       
                    

                    </div>




                </div>

                <div class="col-md-4  padding_custom">
                    <div class="width-sidebar">
                        <div class="live bg_theme p-1">
                            <div class="d-flex justify-content-between">
                                <div>
                                    Live Match
                                </div>
                                <div class=""><i class="fa-solid fa-desktop"></i>
                                    live stream started
                                </div>
                            </div>
                        </div>

                        <div class="live bg_theme p-1 mt-2">
                            <div>
                                PlaceBet
                            </div>
                            <div class="bettingbox">

                                <div class="bet_box">
                                    <div class="bet-slip-box">
                                        <div class="bet-slip">
                                            <div class="bet-nation d-flex justify-content-between">
                                                <span>Match Name</span>
                                                <a href="#" class="close-bet float-right" id="closebetbox"><img
                                                        src="https://wver.sprintstaticdata.com/v9/static/front/img/close.svg"/></a>
                                            </div>
                                            <div class="match-result">
                                                Normal
                                            </div>
                                            <div class="bet-team"><span title="1 to 100 Balls Runs OI(OI vs TR)adv 100 "
                                                    class="bet-team-name">
                                                    1 to 100 Balls Runs OI(OI vs TR)adv

                                                    - 100 </span> <span class="float-right">157</span></div>
                                        </div>
                                        <div class="bet-input lay-border">

                                            <input type="text" id="pressed" value="" maxlength="9" placeholder="Amount"
                                                class="form-control"/>
                                        </div>
                                        <div class="bet-buttons">
                                            <button class="btn btn-primary" type="button"><span
                                                    class="valueamount">1k</span></button>
                                            <button class="btn btn-primary" type="button"><span
                                                    class="valueamount">2k</span></button>
                                            <button class="btn btn-primary" type="button"><span
                                                    class="valueamount">5k</span></button>
                                            <button class="btn btn-primary" type="button"><span
                                                    class="valueamount">10k</span></button>
                                            <button class="btn btn-primary" type="button"><span
                                                    class="valueamount">20k</span></button>
                                            <button class="btn btn-primary" type="button"><span
                                                    class="valueamount">25k</span></button>
                                            <button class="btn btn-primary" type="button"><span
                                                    class="valueamount">50k</span></button>
                                            <button class="btn btn-primary" type="button"><span
                                                    class="valueamount">75k</span></button>
                                        </div>
                                        <div class="place-bet-btn">
                                            <button class="btn btn-success  placebet btn-block"><span>Place bet</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="live bg_theme p-1 mt-2">
                            <div class="d-flex justify-content-between">
                                <div>
                                    My Bet
                                </div>

                            </div>
                            <div class="d-flex justify-content-between bg_matched">
                                <div>
                                    Matched Bet
                                </div>
                                <div class="d-flex justify-content-between oddstake">
                                    <p class="">Odds</p>
                                    <p>Stake</p>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>


            </div>

        </div>
    </main>
</section>

</>
  
  )
}

export default Tennis;