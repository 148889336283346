import React, { useState, useEffect } from "react";

function ProfitLossReport() {
  const [fromDate, setFromDate] = useState("2023-01-01");
  const [toDate, setToDate] = useState("2023-12-31");
  const [filteredData, setFilteredData] = useState({ Profilt: [], Loss: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDataFromAPI = async () => {
    try {
      const token = localStorage.getItem("token_store_cricket");
      const apiUrl = `${process.env.REACT_APP_API_URL}/profit-loss?fromDate=${fromDate}&toDate=${toDate}`;
    
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const data = await response.json();

      const profitLossData = data.data || { Profilt: [], Loss: [] };

      setFilteredData(profitLossData);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [fromDate, toDate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="card-header tab_first d-flex" style={{ height: "55px" }}></div>

      <section>
        <main id="main" className="main">
          <div className="container">
            <div className="accountstatement">
              <h5>Profit and Loss</h5>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label>From Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  id="dateFrom"
                />
              </div>

              <div className="col-md-3">
                <label>To Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  id="dateTo"
                />
              </div>

              <div className="col-md-3 d-flex align-items-flex-end mt-3">
                <button
                  className="btn_custom bg-color d-flex align-items-center"
                  type="button"
                  onClick={fetchDataFromAPI}
                >
                  {/* <i className="fa-solid fa-magnifying-glass pr-5"></i> */}
                  Go
                </button>
                <button
                  className="btn_custom bg-color d-flex align-items-center"
                  type="button"
                  onClick={fetchDataFromAPI}
                >
                  {/* <i className="fa-solid fa-magnifying-glass pr-5"></i> */}
                  Reset
                </button>
              </div>
            </div>

            <table
              id="dataTable"
              className="table profit-los-data"
              data-pagination="true"
              data-page-size="5"
              data-page-list="[5, 10, All]"
              data-search="true"
              data-search-align="left"
              data-striped="true"
              data-show-refresh="true"
              data-sort-name="date"
              data-sort-order="desc"
              data-search-text="Narrow results"
            >
              <thead className="table_account display_change" >
                <tr>
                <th className="col-xs-2" data-field="pl">
                Game Name	
                  </th>
                  <th className="col-xs-5" data-field="balance">
                  Game Type	
                  </th>
                  <th className="col-xs-2" data-field="pl">
                  Profit & Loss

                  </th>
                  {/* <th className="col-xs-2" data-field="creditLimit">
                    Date
                  </th> */}
                
                </tr>
              </thead>
              <tbody className="">
                {filteredData.Profilt.map((item, index) => (
                  <tr key={index}>
                    <td>{item.bet_type}</td>
                    {/* <td>{item.id}</td> */}
                    {/* <td>{item.deposit_amount}</td> */}
                    
                    <td>{item.withdraw_amount}</td>
                    <td>{item.updated_at}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </section>
    </>
  );
}
export default ProfitLossReport;
