import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Siderbar() {
  const [cricketData, setCricketData] = useState([]);
  const [tennisData, setTennisData] = useState([]);
  const [footballData, setFootballData] = useState([]);
  const [gameTypes, setGameTypes] = useState([]);
  const [games, setGames] = useState([]);
  var dropdown = document.getElementsByClassName("dropdown-btn");
  var i;

  for (i = 0; i < dropdown.length; i++) {
    dropdown[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var dropdownContent = this.nextElementSibling;
      if (dropdownContent.style.display === "block") {
        dropdownContent.style.display = "none";
      } else {
        dropdownContent.style.display = "block";
      }
    });
  }


useEffect(() => {
    const token = localStorage.getItem('token_store_cricket');

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    fetch('https://bababooki.in/cadmin/api/v1/games-types', {
      method: 'GET',
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setGames(data.data.games);
      })
      .catch((error) => console.error(error));
  }, []);


  const fetchSportData = async (sportId, setData) => {
    localStorage.setItem('sport_id', sportId);
    const token = localStorage.getItem('token_store_cricket');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/match-list?sport_id=${sportId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      // alert(data.data.matches);
      // console.log(data.data.matches);
      setData(data.data.matches);
    } catch (error) {
      console.error('Error fetching sport data:', error);
    }
  };

  useEffect(() => {
    fetchSportData(4, setCricketData); 
    fetchSportData(2, setTennisData);  
    fetchSportData(1, setFootballData);
  }, []);

 const renderSportItems = (data, sportId,) => {
    const filteredData = data.filter((element) => element.sport_id === sportId);
    return filteredData.map((element) => {
      const storeIdInLocalStorage = (sportId, seriesId) => {
      const url = `/series-list?sport_id=${encodeURIComponent(sportId)}&series_id=${encodeURIComponent(seriesId)}`;
      window.location.href = url;
    };
  
      return (
        <li key={element.id}>
          <Link onClick={() => storeIdInLocalStorage(sportId, element.series_id)}>
            <i className="bi bi-circle"></i>
            <span>{element.name}</span>
          </Link>
        </li>
      );
    });
  };

  return (
    <div>
      <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
      <li className="nav-item">
        <Link className="nav-link " to="/">
          <i className="bi bi-grid"></i>
          <span>Dashboard</span>
        </Link>
      </li>
      {games.map((game) => (
        // alert(game.name),
        // console.log(game.name),
        <li className="nav-item" key={game.id}>
          <a
            className="nav-link collapsed gameListcls"
            data-bs-target={`#${game.name}`}
            data-bs-toggle="collapse"
            href="#"
          >
            <i className="bi bi-journal-text"></i>
            <span>{game.name}</span>
            <i className="bi bi-chevron-down ms-auto"></i>
          </a>
          <ul id={game.name} className="nav-content collapse" data-bs-parent="#sidebar-nav">
            {renderSportItems(cricketData, 4)}
          </ul>
        </li>
      ))}
    </ul>

      </aside>

    </div>
  )
}

export default Siderbar;

