import React, { useState, useEffect } from "react";
import Swal from "sweetalert2"; // Import SweetAlert

function SetButtonValue() {
  const [buttonValues, setButtonValues] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("token_store_cricket");

  useEffect(() => {
    const getButtonValues = async () => {
      try {
        const response = await fetch(
          "https://bababooki.in/cadmin/api/v1/get-button-value",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const rawResponse = await response.json();
        
          const cleanedData = rawResponse.data.map((item) =>
            item.replace(/"/g, "")
          );
          const cleanedResponse = { ...rawResponse, data: cleanedData };
          if (cleanedResponse.status_code === 1) {
            setButtonValues(cleanedResponse.data);
            setInputValues(cleanedResponse.data.slice());
          } else {
            console.error("Invalid data format:", cleanedResponse);
          }
        } else {
          console.error("Failed to fetch button values");
        }
      } catch (error) {
        console.error("Error fetching button values:", error);
      }
    };

    getButtonValues();
  }, []);

  const handleInputChange = (index, newValue) => {
    const updatedInputValues = [...inputValues];
    updatedInputValues[index] = newValue;
    setInputValues(updatedInputValues);
  };

  const handleSetButton = async () => {
    try {
      const postData = {
        value: inputValues.join(","),
      };

      const response = await fetch(
        "https://bababooki.in/cadmin/api/v1/button-value",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postData),
        }
      );

      if (response.ok) {
        const rawResponse = await response.json();
       
        const cleanedData = rawResponse.data.map((item) =>
          item.replace(/"/g, "")
        );
        const cleanedResponse = { ...rawResponse, data: cleanedData };
        if (cleanedResponse.status_code === 1) {
          setSuccessMessage("Successfully updated!");
          setButtonValues(inputValues);
          setErrorMessage("");
        
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Successfully updated!",
            timer: 1000,
            timerProgressBar: true,
          });
        } else {
          console.error("Error updating values:", cleanedResponse);
          setErrorMessage("Error updating values. Please try again.");
          
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error updating values. Please try again.",
            timer: 1000,
            timerProgressBar: true,
          });
        }
      } else {
        console.error("HTTP error:", response.status, response.statusText);
        const errorMessage = await response.text();
        console.error("Error response:", errorMessage);
        setErrorMessage(`HTTP error: ${response.status} - ${errorMessage}`);
       
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `HTTP error: ${response.status} - ${errorMessage}`,
          timer: 1000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      console.error("Error updating values:", error);
      setErrorMessage(
        "An error occurred while updating values. Please try again."
      );
    
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating values. Please try again.",
        timer: 1000,
        timerProgressBar: true,
      });
    }
  };

  return (
    <div>
      <div className="card-header tab_first d-flex" style={{ height: 55 }}>
      </div>
      <section>
        <main id="main" className="main">
          <div className="container-fluid">
            <div className="accountstatement">
              <h5>Set Button Value</h5>
            </div>
            <div className="card">
              <div className="card-header bg-color-setbutton text-white">
                Button Value
              </div>
              <div className="account_statement">
                <div className="container-fluid">
                  <div className="point-inner">
                    <div className="table-responsive">
                      <table className="table button-value">
                        <thead>
                          <tr>
                            <th>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {buttonValues.map((value, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="number"
                                  value={inputValues[index]}
                                  className="form-control"
                                  onChange={(e) =>
                                    handleInputChange(index, e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <button
                        className="btn btn-success"
                        onClick={handleSetButton}
                      >
                        Submit
                      </button>
                      {successMessage && (
                        <div className="alert alert-success">
                          {successMessage}
                        </div>
                      )}
                      {errorMessage && (
                        <div className="alert alert-danger">{errorMessage}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    </div>
  );
}

export default SetButtonValue;

