import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import tvimage from '../../../src/img/tv.png';
import Soccerbro from '../../../src/img/Soccer-bro.png';
import Grandslambro from '../../../src/img/Grandslam-bro.png';

function Home() {
  const [cricketData, setCricketData] = useState([]);
  const [tennisData, setTennisData] = useState([]);
  const [footballData, setFootballData] = useState([]);

  const fetchSportData = async (sportId, setData) => {
    const token = localStorage.getItem('token_store_cricket');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/series-ids?sport_id=${sportId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setData(data.data.matches);
      console.log(data.data.matches);
    } catch (error) {
      console.error('Error fetching sport data:', error);
    }
  };

  useEffect(() => {
    fetchSportData(4, setCricketData);
    fetchSportData(2, setTennisData);
    fetchSportData(1, setFootballData);
  }, []);

  const onclicCricket = (id, event_id, name) => {
    const url = `/cricket?id=${id}&event_id=${event_id}&name=${name}`;
    window.location.href = url;
  };
  const onclicFootball = (id) => {
    const url = `/football?id=${id}`;
    window.location.href = url;
  };
  const onclickTenis = (id) => {
    const url = `/tennis?id=${id}`;
    window.location.href = url;
  };


  const formatDateTime = (dateTimeString) => {
    const options = {
      day: 'numeric',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
    const formattedDate = new Date(dateTimeString).toLocaleString(undefined, options);
      return formattedDate.replace(/\b(\d{1,2}:\d{2})\s+(AM)\b/i, '$1 PM');
  };
  // const formatDateTime = (dateTimeString) => {
  //   const options = {
  //     day: 'numeric',
  //     month: 'short',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     hour12: true,
  //   };
  //   return new Date(dateTimeString).toLocaleString(undefined, options);
  // };

  


  return (
    <div>
      <>
        <div className="card-header tab_first d-flex">
          <div>
            <ul className="nav nav-tabs card-header-tabs" id="outerTab" role="tablist">
              <li className="nav-item activetab">
                <a className="nav-link active" data-toggle="tab" href="#tab-1" aria-controls="tab-1" role="tab" aria-expanded="true">
                  Sports
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#tab-2" aria-controls="tab-2" role="tab">
                  Inplay
                </a>
              </li>
            </ul>
          </div>
        </div>
        <main id="main" className="main">
          <section className="section dashboard">
            <container>
              <div className="">
                <div className="card">
                  <div className="card-body tab-content p-0">
                    <div className="tab-pane active" id="tab-1" role="tabpanel">
                      <div className="card p-0">
                        <div className="card-header pt-0 tab_links">
                          <ul className="nav nav-tabs card-header-tabs" id="innerTab" role="tablist">
                            <li className="nav-item">
                              <a className="nav-link active" data-toggle="tab" href="#tab-1-1" aria-controls="tab-1-1" role="tab" aria-expanded="true">
                                Cricket
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#tab-1-2" aria-controls="tab-1-2" role="tab">Football</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-toggle="tab" href="#tab-1-3" aria-controls="tab-1-3" role="tab">Tenis</a>
                            </li>
                          </ul>
                        </div>
                        <div className="card-body p-0 p-0 tab-content">
                          <div className="tab-pane cricketmatch active" id="tab-1-1" role="tabpanel">
                            <div className="table-responsive">
                              <table className="table gametable">
                                <thead>
                                  <tr>
                                    <th scope="col-2" className="gamename">
                                      Game
                                    </th>
                                    <th className="d-flex justify-content-around headingdivgame">
                                      <div className="headinggame">1</div>
                                      <div className="headinggame">x</div>
                                      <div className="headinggame">2</div>
                                    </th>
                                  </tr>
                                </thead>
                                {cricketData.map((match) => (
                                  <tbody key={match.id}>
                                    {match.series.map((series) => {
                                      const currentDate = new Date();
                                      // alert(currentDate);
                                      const options = {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        second: 'numeric',
                                      };
                                      const formattedCurrentDate = currentDate.toLocaleString(undefined, options);
                                      const matchDate = new Date(series.date_time);
                                      // alert(matchDate);

                                      const isToday = matchDate.toDateString() === currentDate.toDateString();
                                      // alert(isToday);
                                      console.log(isToday);
                                      const isLive = isToday && currentDate >= matchDate;

                                      return (
                                        <tr key={series.id}>
                                          <td scope="row">
                                            <div className="d-flex justify-content-between">
                                              <div className='gamelink '>
                                                <span className="date">{formatDateTime(series.date_time)}</span>
                                                <Link onClick={() => onclicCricket(series.id, series.event_id, series.name)}>
                                                  {series.name}
                                                </Link>
                                                {isLive ? (
                                                  <>
                                                    <div className='d-flex align-items-center'>
                                                      <span className="live-text" style={{ color: '#000', paddingLeft: '10px' }}>Live</span>
                                                      <span className="activegreen mx-2" />
                                                    </div>
                                                  </>
                                                ) : (
                                                  <>

                                                  </>
                                                )}
                                              </div>
                                              <div className="d-flex justify-content-between align-items-center activeanddeactive">
                                                <i className="fa-solid fa-desktop" />
                                                <i className="fa-brands fa-facebook" />
                                                <div className="bm">
                                                  <img src={tvimage} className="img-fluid" alt="tv" />
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td className='mobileheading'>
                                            <div className="d-flex justify-content-around">
                                              <div className='headinggame'>1</div>
                                              <div className='headinggame'>x</div>
                                              <div className='headinggame'>2</div>
                                            </div>
                                          </td>
                                          <td className='d-flex headinggame position-relative serieslist'>
                                            <div className="d-flex pointsrate ">
                                              <div className="bg_bluecolor "><button>1.00</button></div>
                                              <div className="bg-color-2"><button>1.00</button></div>
                                            </div>
                                            <div className="d-flex pointsrate suspended">
                                              <div className="bg_bluecolor"><button>1.00</button></div>
                                              <div className="bg-color-2"><button>1.00</button></div>
                                            </div>
                                            <div className="d-flex  pointsrate suspended">
                                              <div className="bg_bluecolor"><button>1.00</button></div>
                                              <div className="bg-color-2"><button>1.00</button></div>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                ))}

                              </table>
                            </div>
                          </div>
                          <div className="tab-pane" id="tab-1-2" role="tabpanel">
                            <div className="table-responsive">
                              <table className="table gametable">
                                <thead>
                                  <tr>
                                    <th scope="col-2" className="gamename">
                                      Game
                                    </th>
                                    <th className="d-flex justify-content-around headingdivgame">
                                      <div className="headinggame">1</div>
                                      <div className="headinggame">x</div>
                                      <div className="headinggame">2</div>
                                    </th>
                                  </tr>
                                  <td colSpan={2}>
                                    <h5 className='text-center'><i>Comming Soon...</i></h5>
                                    <div className="commingsoon">
                                      <img src={Soccerbro} className='img-fluid' />

                                    </div>
                                  </td>
                                </thead>
                                {/* {footballData.map((match) => (
                                  <tbody>
                                    {match.series.map((series) => (

                                      <tr key={match.id}>
                                        <td key={series.id} scope="row">
                                          <div className="d-flex justify-content-between">
                                            <div className='gamelink '>
                                              <span className="date">12 Sep 03:00 </span>
                                              <a href="#" className="d-flex align-items-center"><span className="mx-2 activegreen" />
                                                {series.name}
                                              </a>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center activeanddeactive">
                                              <i className="fa-solid fa-desktop" />
                                              <i className="fa-brands fa-facebook" />
                                              <div className="bm">
                                                <img src={tvimage} className="img-fluid" alt="tv" />
                                              </div>
                                            </div>
                                          </div>

                                        </td>
                                        <td className='mobileheading'>
                                          <div className="d-flex justify-content-around">
                                            <div className='headinggame'>1</div>
                                            <div className='headinggame'>x</div>
                                            <div className='headinggame'>2</div>
                                          </div>
                                        </td>
                                        <td className='d-flex headinggame position-relative'>
                                          <div className="d-flex ">
                                            <div className="bg_bluecolor"><button>1.00</button></div>
                                            <div className="bg-color-2"><button>1.00</button></div>
                                          </div>
                                          <div className="d-flex suspended">
                                            <div className="bg_bluecolor"><button>1.00</button></div>
                                            <div className="bg-color-2"><button>1.00</button></div>
                                          </div>
                                          <div className="d-flex suspended">
                                            <div className="bg_bluecolor"><button>1.00</button></div>
                                            <div className="bg-color-2"><button>1.00</button></div>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}

                                  </tbody>
                                ))} */}


                                {/* <div className='footballComming'>Comming Soon.....</div> */}


                              </table>
                            </div>
                          </div>
                          <div className="tab-pane" id="tab-1-3" role="tabpanel">
                            <div className="table-responsive">
                              <table className="table gametable">
                                <thead>
                                  <tr>
                                    <th scope="col-2" className="gamename">
                                      Game
                                    </th>
                                    <th className="d-flex justify-content-around headingdivgame">
                                      <div className="headinggame">1</div>
                                      <div className="headinggame">x</div>
                                      <div className="headinggame">2</div>
                                    </th>
                                  </tr>
                                  <td colSpan={2}>
                                    <h5 className='text-center'><i>Comming Soon....</i></h5>
                                    <div className="commingsoon">
                                      <img src={Grandslambro} className='img-fluid' />

                                    </div>
                                  </td>
                                </thead>
                                {/* {tennisData.map((match) => (
                                  <tbody>
                                    {match.series.map((series) => (
                                      <tr key={match.id}>
                                        <td key={series.id} scope="row">
                                          <div className="d-flex justify-content-between">
                                            <div className='gamelink '>
                                              <span className="date">{formatDateTime(series.date_time)} </span>
                                              <a href="#" className="d-flex align-items-center"><span className="mx-2 activegreen" />
                                                {series.name}
                                              </a>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center activeanddeactive">
                                              <i className="fa-solid fa-desktop" />
                                              <i className="fa-brands fa-facebook" />
                                              <div className="bm">
                                                <img src={tvimage} className="img-fluid" alt="tv" />
                                              </div>
                                            </div>
                                          </div>

                                        </td>
                                        <td className='mobileheading'>
                                          <div className="d-flex justify-content-around">
                                            <div className='headinggame'>1</div>
                                            <div className='headinggame'>x</div>
                                            <div className='headinggame'>2</div>
                                          </div>
                                        </td>
                                        <td className='d-flex headinggame position-relative'>
                                          <div className="d-flex ">
                                            <div className="bg_bluecolor"><button>1.00</button></div>
                                            <div className="bg-color-2"><button>1.00</button></div>
                                          </div>
                                          <div className="d-flex suspended">
                                            <div className="bg_bluecolor"><button>1.00</button></div>
                                            <div className="bg-color-2"><button>1.00</button></div>
                                          </div>
                                          <div className="d-flex suspended">
                                            <div className="bg_bluecolor"><button>1.00</button></div>
                                            <div className="bg-color-2"><button>1.00</button></div>
                                          </div>
                                        </td>


                                      </tr>
                                    ))}
                                  </tbody>
                                ))} */}

                                {/* <div className='footballComming'>Comming Soon.....</div> */}

                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Nested Tab-1 */}
                    </div>
                    <div className="tab-pane" id="tab-2" role="tabpanel">
                      {/* Start Nested Tab-2 */}
                      <div className="card p-0">
                        <div className="card-header pt-0 tab_links">
                          <ul className="nav nav-tabs card-header-tabs" id="innerTab" role="tablist">
                            <li className="nav-item">
                              <a className="nav-link active" data-toggle="tab" href="#tab-2-1" aria-controls="tab-2-1" role="tab"
                                aria-expanded="true"> Cricket </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#tab-2-2"
                                aria-controls="tab-2-2"
                                role="tab"
                              >
                                Tennis
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#tab-2-3"
                                aria-controls="tab-2-3"
                                role="tab"
                              >
                                Football
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="card-body p-0 tab-content">
                          <div className="tab-pane active" id="tab-2-1" role="tabpanel">
                            <div className="table-responsive">
                              <table className="table gametable">
                                <thead>
                                  <tr>
                                    <th scope="col-2" className="gamename">
                                      Game
                                    </th>
                                    <th className="d-flex justify-content-around headingdivgame">
                                      <div className="headinggame">1</div>
                                      <div className="headinggame">x</div>
                                      <div className="headinggame">2</div>
                                    </th>
                                  </tr>
                                </thead>
                                {cricketData.map((match) => (
                                  <tbody key={match.id}>
                                    {match.series.map((series) => {
                                      const currentDate = new Date();
                                      const options = {
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        second: 'numeric',
                                        hour12: true,
                                      };
                                      const formattedCurrentDate = currentDate.toLocaleString(undefined, options);
                                      const matchDate = new Date(series.date_time);

                                      const isToday = matchDate.toDateString() === currentDate.toDateString();
                                      // alert(isToday);
                                      console.log(isToday);
                                      const isLive = isToday && currentDate >= matchDate;

                                      return (
                                        <tr key={series.id}>

                                          {isLive ? (
                                            <>
                                              <td scope="row">
                                                <div className="d-flex justify-content-between">
                                                  <div className='gamelink '>
                                                    <span className="date">{formatDateTime(series.date_time)}</span>
                                                    <Link onClick={() => onclicCricket(series.id, series.event_id, series.name)}>
                                                      {series.name}
                                                      <span className="live-text" style={{ color: '#000', paddingLeft: '10px' }}>Live</span>
                                                      <span className="mx-2 activegreen" />
                                                    </Link>

                                                  </div>
                                                  <div className="d-flex justify-content-between align-items-center activeanddeactive">
                                                    <i className="fa-solid fa-desktop" />
                                                    <i className="fa-brands fa-facebook" />
                                                    <div className="bm">
                                                      <img src={tvimage} className="img-fluid" alt="tv" />
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                              <td className='mobileheading'>
                                                <div className="d-flex justify-content-around">
                                                  <div className='headinggame'>1</div>
                                                  <div className='headinggame'>x</div>
                                                  <div className='headinggame'>2</div>
                                                </div>
                                              </td>
                                              <td className='d-flex headinggame position-relative serieslist'>
                                                <div className="d-flex pointsrate ">
                                                  <div className="bg_bluecolor "><button>1.00</button></div>
                                                  <div className="bg-color-2"><button>1.00</button></div>
                                                </div>
                                                <div className="d-flex pointsrate suspended">
                                                  <div className="bg_bluecolor"><button>1.00</button></div>
                                                  <div className="bg-color-2"><button>1.00</button></div>
                                                </div>
                                                <div className="d-flex  pointsrate suspended">
                                                  <div className="bg_bluecolor"><button>1.00</button></div>
                                                  <div className="bg-color-2"><button>1.00</button></div>
                                                </div>
                                              </td>

                                            </>
                                          ) : (
                                            <>

                                            </>
                                          )}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                ))}
                              </table>
                            </div>
                          </div>
                          <div className="tab-pane" id="tab-2-2" role="tabpanel">
                            <div className="table-responsive">
                              <table className="table gametable">
                                <thead>
                                  <tr>
                                    <th scope="col-2" className="gamename">
                                      Game
                                    </th>
                                    <th className="d-flex justify-content-around headingdivgame">
                                      <div className="headinggame">1</div>
                                      <div className="headinggame">x</div>
                                      <div className="headinggame">2</div>
                                    </th>
                                  </tr>
                                </thead>
                                {/* {tennisData.map((match) => (
                                  <tbody>
                                    {match.series.map((series) => (
                                      <tr key={match.id}>
                                        <td key={series.id} scope="row">
                                          <div className="d-flex justify-content-between">
                                            <div className='gamelink '>
                                              <span className="date">12 Sep 03:00 </span>
                                              <a href="#" className="d-flex align-items-center"><span className="activegreen" />
                                                {series.name}
                                              </a>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center activeanddeactive">
                                              <i className="fa-solid fa-desktop" />
                                              <i className="fa-brands fa-facebook" />
                                              <div className="bm">
                                                <img src={tvimage} className="img-fluid" alt="tv" />
                                              </div>
                                            </div>
                                          </div>

                                        </td>
                                        <td className='mobileheading'>
                                          <div className="d-flex justify-content-around">
                                            <div className='headinggame'>1</div>
                                            <div className='headinggame'>x</div>
                                            <div className='headinggame'>2</div>
                                          </div>
                                        </td>
                                        <td className='d-flex headinggame position-relative'>
                                          <div className="d-flex ">
                                            <div className="bg_bluecolor"><button>1.00</button></div>
                                            <div className="bg-color-2"><button>1.00</button></div>
                                          </div>
                                          <div className="d-flex suspended">
                                            <div className="bg_bluecolor"><button>1.00</button></div>
                                            <div className="bg-color-2"><button>1.00</button></div>
                                          </div>
                                          <div className="d-flex suspended">
                                            <div className="bg_bluecolor"><button>1.00</button></div>
                                            <div className="bg-color-2"><button>1.00</button></div>
                                          </div>
                                        </td>


                                      </tr>
                                    ))}
                                  </tbody>
                                ))} */}


                                <div className='footballComming'>Comming Soon.....</div>
                              </table>
                            </div>
                          </div>
                          <div className="tab-pane" id="tab-2-3" role="tabpanel">
                            <div className="table-responsive">
                              <table className="table gametable">
                                <thead>
                                  <tr>
                                    <th scope="col-2" className="gamename">
                                      Game
                                    </th>
                                    <th className="d-flex justify-content-around headingdivgame">
                                      <div className="headinggame">1</div>
                                      <div className="headinggame">x</div>
                                      <div className="headinggame">2</div>
                                    </th>
                                  </tr>
                                </thead>
                                {/* {footballData.map((match) => (
                                  <tbody>
                                    {match.series.map((series) => (
                                      <tr key={match.id}>
                                        <td key={series.id} scope="row">
                                          <div className="d-flex justify-content-between">
                                            <div className='gamelink '>
                                              <span className="date">12 Sep 03:00 </span>
                                              <a href="#" className="d-flex align-items-center"><span className="activegreen" />
                                                {series.name}
                                              </a>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center activeanddeactive">
                                              <i className="fa-solid fa-desktop" />
                                              <i className="fa-brands fa-facebook" />
                                              <div className="bm">
                                                <img src={tvimage} className="img-fluid" alt="tv" />
                                              </div>
                                            </div>
                                          </div>

                                        </td>
                                        <td className='mobileheading'>
                                          <div className="d-flex justify-content-around">
                                            <div className='headinggame'>1</div>
                                            <div className='headinggame'>x</div>
                                            <div className='headinggame'>2</div>
                                          </div>
                                        </td>
                                        <td className='d-flex headinggame position-relative'>
                                          <div className="d-flex ">
                                            <div className="bg_bluecolor"><button>1.00</button></div>
                                            <div className="bg-color-2"><button>1.00</button></div>
                                          </div>
                                          <div className="d-flex suspended">
                                            <div className="bg_bluecolor"><button>1.00</button></div>
                                            <div className="bg-color-2"><button>1.00</button></div>
                                          </div>
                                          <div className="d-flex suspended">
                                            <div className="bg_bluecolor"><button>1.00</button></div>
                                            <div className="bg-color-2"><button>1.00</button></div>
                                          </div>
                                        </td>


                                      </tr>
                                    ))}
                                  </tbody>
                                ))} */}


                                <div className='footballComming'>Comming Soon.....</div>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </container>
          </section>
        </main>
        {/*?php include("footer.php") ?*/}
      </>

    </div>
  )
}

export default Home