import React from 'react'

function Newsecurity() {
  return (
    <div><>
    {/*?php include("header.php") ?*/}
    <div className="card-header tab_first d-flex" style={{ height: 55 }}></div>
    <section>
      <main id="main" className="main">
        <div className="container">
          <div className="accountstatement">
            <h5>Security Auth Verification </h5>
          </div>
          <div className="account_statement">
            <div className="container">
              <div className="mt-5">
                <center>
                  <h6 className="secure">
                    Secure Auth Verification Status:{" "}
                    <span className="Disabled_btn">Disabled</span>
                  </h6>
                  <h6 className="secure mt-3">
                    Please select below option to enable secure auth verification
                  </h6>
                  {/* tabs */}
                  <div className="tabs_new">
                    <ul className="tabs">
                      <li data-tab-target="#home-content" className="tab">
                        Eanable Using Mobile App
                      </li>
                      <li data-tab-target="#about-content" className="tab">
                        Enable Using Telegram
                      </li>
                    </ul>
                    <div className="tab-contents">
                      <div className="tab-content" id="home-content">
                        <h6 className="secure">
                          Please enter below auth code in your 'Secure Auth
                          Verification App'.
                        </h6>
                        <div onload="functionToCall()">
                          <div className="verify-code" id="random-number" />
                        </div>
                        <h6 className="secure">
                          If you haven't downloaded,
                          <span className="d-block" />
                          please download 'Secure Auth Verification App' from
                          below link.
                        </h6>
                        <h6 className="secure pt-2">
                          Using this app you will receive auth code during login
                          authentication
                        </h6>
                        <button className="btn btn-success downloadanroidapk mt-2">
                          <i className="fa-solid fa-download" />
                          Download on the Android
                        </button>
                      </div>
                      <div className="tab-content" id="about-content">
                        <h6 className="secure">
                          Please enter your login password to continue
                        </h6>
                        <div className="d-flex justify-content-center mt-3">
                          <input type="text" className="form-control w-25" />
                          <button className="btn btn-success ml_5">
                            Get Generate ID
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </center>
              </div>
              {/* Row */}
            </div>
          </div>
        </div>
      </main>
    </section>
    {/*?php include("footer.php") ?*/}
  </>
  </div>
  )
}

export default Newsecurity