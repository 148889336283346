const fetchwalletamount = async (setWalletAmount, setexpamount) => {
  const token = localStorage.getItem('token_store_cricket');
  try {
    const response = await fetch(
      'https://bababooki.in/cadmin/api/v1/user/wallet-amount',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    setWalletAmount(data.amount);
    setexpamount(data.exp);
    
  } catch (error) {
    console.error('Error fetching wallet amount:', error);
  }
};

export { fetchwalletamount};