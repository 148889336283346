import React, { useState, useEffect } from "react";
// import axios from "axios";
import ReactHtmlParser from "react-html-parser";
// import Loader from './Loader';
// import 'fancybox/dist/jquery.fancybox.min.css'; // Import Fancybox CSS
import $ from 'jquery'; // Import jQuery
import { Link } from "react-router-dom";
import axios from "axios";
export default function BroadCast() {
  const [users, setUsers] = useState([]);

  const userid1 = localStorage.getItem("userid");
  // const [loading1, setLoading] = useState(false);
  // alert("fmbjgfdgj")
  useEffect(() => {
    playGame();
  }, []);
  const playGame = async () => {

    try {
      const formData = new FormData();
      formData.append('user_id', userid1);
  
      const response = await axios.post(
        'https://bababooki.in/api/broadcast.php',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
  
      const result = response.data.data;
      setUsers(result);
      localStorage.setItem("notificationCount", result.length);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // const playGame = async () => {
  //   // alert("jdfgjdf")
  //   try {
  //     const response = await axios.get(
  //       `https://bababooki.in/api/broadcast.php`
  //     );
  //     const result = response.data.data;
  //     // console.warn(result);
  //     // setUservideosss(result);
      
  //     setUsers(result);
  //     localStorage.setItem("notificationCount", result.length);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  useEffect(() => {
    playGame();

    setTimeout(() => {}, 1000);
  }, []);
  // const handleReload = () => {

  //   playGame();
  //   window.scrollTo(0, 0);
  // };
  
  return (
    <>
      <div className="card-header tab_first d-flex">
        <div>
          <ul
            className="nav nav-tabs card-header-tabs"
            id="outerTab"
            role="tablist"
          >
            <li className="nav-item activetab">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#tab-1"
                aria-controls="tab-1"
                role="tab"
                aria-expanded="true"
              >
                Sports
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                href="#tab-2"
                aria-controls="tab-2"
                role="tab"
              >
                Inplay
              </a>
            </li>
          </ul>
        </div>
      </div>
      <main id="main" class="main">
        <section id="">
          <div className="">
            {users.map((index) => (
              <>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-danger">
                      {index.title.toUpperCase()}
                    </h5>
                    <p className="card-text">
                      {index.type === "image" && (
                        <Link
                          data-fancybox
                          data-src={index.media}
                          data-caption="Hello world"
                        >
                          <img
                            src={index.media}
                            style={{ height: "100px" }}
                            alt="Media"
                          />
                        </Link>
                      )}
                      {index.type === "video" && (
                        <video controls width="300" height="200">
                          <source src={index.media} type="video/mp4" />
                          {/* Your browser does not support the video tag. */}
                        </video>
                      )}
                      {index.type === "text" && <></>}
                      {index.type === "audio" && (
                        <audio controls>
                          <source src={index.media} type="audio/mp3" />
                        </audio>
                      )}
                    </p>
                    <p className="card-text">
                      <span>{index.message}</span>
                      <br />
                      <span>
                        <Link to={index.link}>{index.link}</Link>
                      </span>
                      <br />
                      <span>Date: {index.date}</span>
                    </p>
                  </div>
                </div>
              </>
            ))}

            <div></div>
          </div>
        </section>
      </main>
    </>
  );
}
