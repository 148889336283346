import React from 'react'

function Newpassword() {
  return (
    <div>
        <>
 
  <div className="card-header tab_first d-flex" style={{ height: 55 }}></div>
  <section>
    <main id="main" className="main">
      <div className="container">
        <div className="accountstatement">
          <h5>Change Password</h5>
        </div>
        <div className="passwordchange">
          <div className="container">
            <form id="password" role="form" style={{}}>
              <div className="row d-flex align-items-center">
                <div className="col-md-12 mt-3">
                  <label>Old Password</label>
                  <div className="position-relative mb-2">
                    <input type="password" className="form-control" />
                  </div>
                </div>
                <div className="col-md-12">
                  <label>New Password</label>
                  <div className="position-relative mb-2">
                    <input type="password" className="form-control password" />
                    <div className="seepassword" onclick="passwordshow()">
                      <i className="fa-solid fa-eye toggle-password" />
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label>Repeat Password</label>
                  <div className="position-relative mb-2">
                    <input
                      type="password"
                      className="form-control repeatpassword"
                    />
                    <div className="seepassword" onclick="passwordshow()">
                      <i className="fa-solid fa-eye toggle-passwordrepeat" />
                    </div>
                  </div>
                </div>
                <div className="col-md-3 d-flex align-items-flex-end mt-3">
                  <button
                    className="btn bg-color btnsave sendButton"
                    type="button"
                    onclick="myAlertTopp()"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
            <div className="myAlert-top alert alert-success">
              <a
                href="#"
                className="close"
                data-dismiss="alert"
                aria-label="close"
              >
                ×
              </a>
              <strong>Success!</strong>Your Password Successfully Update
            </div>
          
          </div>
        </div>
      </div>
    </main>
  </section>
  
</>

    </div>
  )
}

export default Newpassword