import React from 'react'

function Setbuttonnew() {
  return (
    <div>
        <>
  {/*?php include("header.php") ?*/}
  <div className="card-header tab_first d-flex" style={{ height: 55 }}></div>
  <section>
    <main id="main" className="main">
      <div className="container-fluid">
        <div className="accountstatement">
          <h5>set button value </h5>
        </div>
        <div className="account_statement">
          <div className="container-fluid">
            <div className="mt-5 point-inner">
              <div className="table-responsive">
                <table className="table button-value">
                  <thead>
                    <tr>
                      <th>Price Label</th>
                      <th>Price Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="text"
                          defaultValue="1k"
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                      <td>
                        <input
                          type="number"
                          defaultValue={1000}
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          defaultValue="2k"
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                      <td>
                        <input
                          type="number"
                          defaultValue={2000}
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          defaultValue="3k"
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                      <td>
                        <input
                          type="number"
                          defaultValue={3000}
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          defaultValue="4k"
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                      <td>
                        <input
                          type="number"
                          defaultValue={4000}
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          defaultValue="5k"
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                      <td>
                        <input
                          type="number"
                          defaultValue={5000}
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          defaultValue="6k"
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                      <td>
                        <input
                          type="number"
                          defaultValue={6000}
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          defaultValue="7k"
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                      <td>
                        <input
                          type="number"
                          defaultValue={7000}
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <input
                          type="text"
                          defaultValue="8k"
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                      <td>
                        <input
                          type="number"
                          defaultValue={8000}
                          className="form-control"
                        />{" "}
                        {/**/}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <button className="btn btn-success w-100">
                          Submit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* Row */}
          </div>
        </div>
      </div>
    </main>
  </section>
  {/*?php include("footer.php") ?*/}
</>

    </div>
  )
}

export default Setbuttonnew