import React, { useState, useEffect } from "react";
import whatsapp from '../../img/whatsapp.png'
import { Link } from 'react-router-dom';
import apk from '../../img/apkbutton.png'
import callimg from '../../img/callimg.png';
function Footer() {
  const [mobileNumber, setMobileNumber] = useState("");

  const fetchDataFromAPI = async () => {
    try {
      const token = localStorage.getItem("token_store_cricket");
      const apiUrl = `${process.env.REACT_APP_API_URL}/general-setting`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const data = await response.json();
      setMobileNumber(data.data.mobile);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, []);

  return (
    <div>
        <>
  {/* ======= Footer ======= */}
  <footer id="footer" className="footer">
    <div className="copyright">
      © Copyright 2023 - 2024
      <strong>
        <span> All Rights Reserved</span>
      </strong>
      . Powered by Bababooki
    </div>
  </footer>

                               

 <div className="apk ">

<a href="../assets/application.apk"><img src={apk} className="img-fluid" /></a>
</div>
 <div className="whatsappicon ">

<div className="d-flex align-items-center">
  <div className="callimg">
  <a
      href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(mobileNumber)}`}
      target="_blank"
      rel="noopener noreferrer"
      className="d-flex align-items-center"
    >
<img className="numbercall" src={callimg}/>
</a>
  </div>
  {/* <div className="whatsappiconimage">
 <a
      href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(mobileNumber)}`}
      target="_blank"
      rel="noopener noreferrer"
      className="d-flex align-items-center"
    >

      <img src={whatsapp} className='img-fluid'/>
    </a>
   
   </div> */}
</div>


    
 
  
 </div>
  {/* End Footer */}
  {/* Button trigger modal */}
  {/* ***********************************Modal********************************* start */}
  {/* ***********************************Modal********************************* Rule */}
  <div
    className="modal fade"
    id="exampleModal"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Rule
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        <div className="modal-body">
          <div className="">
            <div className="tabs-container">
              <div className="tab active">
                <a href="" className="tab-title">
                  Horse Racing
                </a>
              </div>
              <div className="tab-content_rule" id="">
                Here is the content 1... Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
                enim ad minim veniam, quis nostrud exerci tation ullamcorper
                suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis
                autem vel eum iriure dolor in hendrerit in vulputate velit esse
                molestie consequat
              </div>
              <div className="tab">
                <a href="" className="tab-title">
                  Handball
                </a>
              </div>
              <div className="tab-content_rule" id="">
                Here is the content 2... Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
                enim ad minim veniam, quis nostrud exerci tation ullamcorper
                suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis
                autem vel eum iriure dolor in hendrerit in vulputate velit esse
                molestie consequat
              </div>
              <div className="tab">
                <a href="" className="tab-title">
                  Table Tennis
                </a>
              </div>
              <div className="tab-content_rule " id="">
                Here is the content 3... Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
                enim ad minim veniam, quis nostrud exerci tation ullamcorper
                suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis
                autem vel eum iriure dolor in hendrerit in vulputate velit esse
                molestie consequat
              </div>
              <div className="tab">
                <a href="" className="tab-title">
                  Basketball
                </a>
              </div>
              <div className="tab-content_rule " id="">
                Here is the content 4... Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
                enim ad minim veniam, quis nostrud exerci tation ullamcorper
                suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis
                autem vel eum iriure dolor in hendrerit in vulputate velit esse
                molestie consequat
              </div>
              <div className="tab">
                <a href="" className="tab-title">
                  Vollyball
                </a>
              </div>
              <div className="tab-content_rule " id="">
                Here is the content 5... Lorem ipsum dolor sit amet,
                consectetuer adipiscing elit, sed diam nonummy nibh euismod
                tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
                enim ad minim veniam, quis nostrud exerci tation ullamcorper
                suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis
                autem vel eum iriure dolor in hendrerit in vulputate velit esse
                molestie consequat
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  {/* ***********************************Modal********************************* Rule */}
  {/* ***********************************Modal********************************* Rule */}
  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
        class="bi bi-arrow-up-short"></i></a>
  <div
    className="modal fade"
    id="infomodel"
    tabIndex={-1}
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="d-flex bg_theme justify-content-between align-items-center">
          <div className="card-body p-1 bg_theme heading_match d-flex justify-content-between">
            <p className="text-white pl_15"> Match</p>
            <button
              type="button"
              className="close_btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="fa-solid fa-xmark text-white closebtn" />
            </button>
          </div>
        </div>
        <div className="modal-body table_match card-body pt-3">
          <div className="table-responsive">
            <table className="table w-100">
              <tbody>
                <tr>
                  <td>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.{" "}
                  </td>
                </tr>
                <tr>
                  <td>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</>
<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
        class="bi bi-arrow-up-short"></i></a>
    </div>
    
  )
}

export default Footer